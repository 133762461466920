<template>
    <div class="search-results-container">
        <Offers :offers="searchResults" />
    </div>
    <div class="load-more-container">
        <div class="results-info" v-show="searchResultsLength > 0">{{ searchResultsLength }} Ergebnisse von {{ totalItems }}
        </div>
        <button class="load-more-button" @click.prevent="onShowMoreClicked" v-show="showMoreButton">weitere
            Angebote</button>
        <div class="affiliate-container">
            <div class="affiliate-card affiliate-amazon" @click="onAmazonClicked">
                <div class="affiliate-text">Finden Sie weitere<br /> Angebote</div>
                <img src="../../assets/amazon-logo.svg" />
            </div>
            <div class="affiliate-card affiliate-ebay" @click="onEbayClicked">
                <div class="affiliate-text">Finden Sie weitere<br /> Angebote</div>
                <img src="../../assets/ebay-logo.svg" />
            </div>
        </div>
    </div>
</template>
  
<script>
import Offers from '@/components/offer/Offers.vue'

export default {
    components: {
        Offers
    },
    props: ['searchResults', 'showMoreButton', 'totalItems'],
    data() {
        return {
            searchResultsLength: 0
        }
    },
    methods: {
        onShowMoreClicked() {
            this.$parent.onShowMoreClicked()
        },
        onAmazonClicked() {
            this.$parent.onAmazonClicked()
        },
        onEbayClicked() {
            this.$parent.onEbayClicked()
        }
    },
    watch: {
        searchResults: {
            handler(newVal, _) {
                if (newVal == null) {
                    this.searchResultsLength = 0
                } else {
                    this.searchResultsLength = parseInt(newVal.length).toLocaleString('de-DE');
                }
            },
            deep: true,
            immediate: true,
        }
    }
}
</script>
<style>
/* Best Products Section */

.search-results-container {
    display: grid;
    gap: 1em;
}

.results-info {
    color: var(--primary-black);
    font-size: var(--fs-400);
    font-weight: var(--fw-500);
    margin-bottom: 10px;
}

.load-more-container {
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.load-more-button {
    border-radius: 6px;
    padding: 1em;
    border: 0px;
    background-color: rgba(var(--primary-color-rgba), 0.4);
    transition: background-color 0.4s;
    color: var(--white);
    font-weight: var(--fw-700);
    font-size: 20px;
    font-family: var(--ff-pirmary);
    font-variant: small-caps;
    letter-spacing: .02rem;
}

.load-more-button:hover {
    background-color: var(--primary-color);
    cursor: pointer;
}

.affiliate-container {
    display: flex;
    margin-top: 2em;
    gap: 2em;
}

.affiliate-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 6px;
}

.affiliate-card:hover {
    cursor: pointer;
}

.affiliate-text {
    color: var(--primary-black);
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    text-align: center;
}

.affiliate-amazon {
    background: rgba(247, 155, 52, 0.15);
}

.affiliate-ebay {
    background: rgba(134, 184, 23, 0.15);
}

/* Width >= 1920px */
@media (width >=120em) {
    .search-results-container {
        grid-template-columns: repeat(4, 3fr);
    }
}

/* Width >= 1536px and width < 1920px */
@media (width >=96em) and (width < 120em) {
    .search-results-container {
        grid-template-columns: repeat(4, 3fr);
    }
}

/* Width >= 1280px and width < 1536px */
@media (width >=80em) and (width < 96em) {
    .search-results-container {
        grid-template-columns: repeat(3, 4fr);
    }
}

/* Width >= 1140px and width < 1280px */
@media (width >=71.25em) and (width < 80em) {
    .search-results-container {
        grid-template-columns: repeat(3, 4fr);
    }
}

/* Width >= 1024px and width < 1140px */
@media (width >=64em) and (width < 71.25em) {
    .search-results-container {
        grid-template-columns: repeat(2, 6fr);
    }
}

/* Width >= 896px and width < 1024px */
@media (width >=56em) and (width < 64em) {
    .search-results-container {
        grid-template-columns: repeat(2, 6fr);
    }
}

/* Width >= 768px and width < 896px */
@media (width >=48em) and (width < 56em) {
    .search-results-container {
        grid-template-columns: repeat(3, 4fr);
    }
}

/* Width >= 568px and width < 768px */
@media (width >=35.5em) and (width < 48em) {
    .search-results-container {
        grid-template-columns: repeat(2, 6fr);
    }
}

/* Width < 568px */
@media (width < 35.5em) {
    .search-results-container {
        grid-template-columns: repeat(1, 12fr);
    }
}
</style>